"use strict";
/**
 * Polyfills for Wagtail's admin.
 */
exports.__esModule = true;
// IE11.
require("core-js/shim");
// IE11, old iOS Safari.
require("whatwg-fetch");
// IE11.
require("element-closest");
